<template>
  <div class="actions">
    <BrandButton
      :size="sizes.xSmall"
      iconName="IconSheetLines"
      :outlined="routes.priceList != activeRoute"
      :target="routes.priceList"
      :iconColor="routes.priceList == activeRoute ? '#ffffff' : 'currentColor'"
      as="router-link"
      :hideIconOnSmallScreen="true"
      @click.native="dataLayerEvent()"
    >
      <VueText
        :color="routes.priceList == activeRoute ? 'white-100' : 'currentColor'"
        sizeLevel="3"
        weightLevel="3"
        >Güncel Fiyat Listesi</VueText
      >
    </BrandButton>
    <BrandButton
      :size="sizes.xSmall"
      iconName="IconSpeechBubble"
      :iconColor="routes.productList == activeRoute ? '#ffffff' : 'currentColor'"
      :outlined="routes.productList != activeRoute"
      :target="routes.productList"
      as="router-link"
      :hideIconOnSmallScreen="true"
    >
      <VueText
        :color="routes.productList == activeRoute ? 'white-100' : 'currentColor'"
        sizeLevel="3"
        weightLevel="3"
        >Güncel Ürün Listesi</VueText
      >
    </BrandButton>
  </div>
</template>
<script>
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import { ICON_VARIABLES, COMPONENT_SIZES } from '@/constants/component.constants';
import gtmUtils from '@/mixins/gtmUtils.js';

export default {
  name: 'BrandProductPriceToolbar',
  mixins: [gtmUtils],
  data() {
    return {};
  },
  props: {
    partner: {
      type: String,
      required: true,
    },
    routes: {
      type: Object,
      required: true,
    },
    activeRoute: {
      type: String,
      required: true,
    },
  },
  methods: {
    dataLayerEvent() {
      this.pushDataLayerEvent('fiyat_listesi_click', {
        location: 'business-partner',
        partner: this.partner,
      });
    },
  },
  components: {
    BrandButton,
    VueText,
  },
  computed: {
    icon() {
      return {
        sheetLines: ICON_VARIABLES.sheetLines,
        speechBubble: ICON_VARIABLES.speechBubble,
      };
    },
    sizes() {
      return COMPONENT_SIZES;
    },
  },
};
</script>
<style scoped lang="scss">
.actions {
  display: flex;
  padding: palette-space-level(20);
  z-index: 5;
  a:not(:first-child) {
    margin-left: palette-space-level(10);
  }
  a:not(:last-child) {
    margin-right: palette-space-level(10);
  }
}
</style>
